// //Homepage.js
// import React, { useEffect, useState, useContext, useRef } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import ChatHistory from "../components/ChatHistory";
// import axios from "axios";
// import logo from '../../src/logo.png'
// import logo1 from '../../src/ET.png'
// import {
//   Box,
//   Typography,
//   useTheme,
//   useMediaQuery,
//   TextField,
//   Button,
//   Alert,
//   Collapse,
//   Card,
//   Table,
//   TableHead,
//   TableRow,
//   TableCell,
//   TableBody,
//   Fade,
//   CircularProgress
// } from "@mui/material";
// import { AuthContext } from "../private/AuthContext";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
// import CryptoJS from "crypto-js";
// const decryptData = (encryptedData) => {
//   try {
//     const bytes = CryptoJS.AES.decrypt(encryptedData, "secretKey");
//     const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
//     return JSON.parse(decryptedData);
//   } catch (error) {
//     console.error("Error decrypting data:", error);
//     return null;
//   }
// };

// const esClient = axios.create({
//   baseURL: 'https://c0ba5b40e16d49f3b0d57c0f0a7d4188.us-central1.gcp.cloud.es.io:443',
//   headers: {
//     'Authorization': 'Basic ' + btoa('elastic:LwPBymDvPE1qpL2DDe8x4CIJ')
//   }
// });

// const Homepage = () => {
//   const { chat } = useContext(AuthContext);
//   const [user, setUser]=useState({});
//   const theme = useTheme();
//   const navigate = useNavigate();
//   const isNotMobile = useMediaQuery("(min-width: 1000px)");
//   const [title, setTitle] = useState("");
//   const [summary, setSummary] = useState(""); // State for text summary
//   const [text, settext] = useState("");
//   const [response, setResponse] = useState(" ");
//   const [error, setError] = useState("");
//   const [loading, setLoading] = useState(false);
  
//   const tableRef = useRef(null);
//   useEffect(()=>{

//     const encryptedUserData = localStorage.getItem("userData");
//     if (encryptedUserData) {
//       const usr = decryptData(encryptedUserData);
//       setUser(usr);
//     }
    

//   },[])
//   useEffect(()=>{
//     if(chat){
//       if(chat.query){
//         settext(chat.query);
//         setTitle(chat.query);
//         setResponse(JSON.parse(chat.llm_data));
//       }
//     }

//   },[chat])
//   // const handleSubmit = async (e) => {
//   //   setResponse('')
//   //   setLoading(true);
//   //   e.preventDefault();
//   //   try {
//   //     await axios.post("/api/v1/openai/llm", { text }).then(async (res) => {
//   //       let query = res?.data?.data?.data?.sql_query;
//   //       await axios.post("/api/v1/openai/chatbot", { query }).then(async (resp) => {
//   //         let responseData = resp.data;
  
//   //         // Limit the response to the first 100 items
//   //         if (Array.isArray(responseData)) {
//   //           responseData = responseData.slice(0, 100);
//   //         }
  
//   //         setResponse(responseData);
//   //         setLoading(false);
//   //         const llm_data = JSON.stringify(responseData);
//   //         //console.log("user", user)
//   //         await axios.post("/api/v1/openai/chathistory", { userId: user.id, query: text, llm_data });
//   //       });
//   //     });
//   //   } catch (err) {
//   //     setLoading(false);
//   //     //console.log(err);
//   //     if (err?.response?.data?.error) {
//   //       console.log("this is llm error");
//   //       setError("We are sorry but we cannot answer this query at this point of time");
//   //     } else if (err?.message) {
//   //       setError(err.message);
//   //     }
//   //     setTimeout(() => {
//   //       setError("");
//   //     }, 5000);
//   //   }
//   // };


//   const handleSubmit = async (e) => {

//     e.preventDefault();
//     console.log(title)
//     setResponse([]);
//     setSummary(""); // Clear summary before fetching a new one
//     setLoading(true);
//     try {
// const targetUrl =  'http://localhost:8081/api/scraper/searchWithBedrock';
         
//       const response = await axios({
//         method: 'POST',
//         url: targetUrl,
//         mode:"cors",
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': 'Basic ' + btoa('elastic:LwPBymDvPE1qpL2DDe8x4CIJ')
//         },
//         params: {
//         question:title
//         },
//       });
//       console.log(response.data);


//       // Safely process the response
//       const hits = response.data && response.data.hits && response.data.hits.hits ? response.data.hits.hits : [];
//       const processedHits = hits.map(hit => ({
//         id: hit._id,
//         content: hit._source.content // Adjust according to your data structure
//       }));

//       setResponse(processedHits);
//       setLoading(false);
//     } catch (err) {
//       setLoading(false);
//       console.error("Error fetching data:", err);
//       if (err?.response?.data?.error) {
//         setError(err.response.data.error);
//       } else if (err?.message) {
//         setError(err.message);
//       }
//       setTimeout(() => {
//         setError("");
//       }, 5000);
//     }
//   };

//   const exportAsPDF = () => {
//     const input = tableRef.current;
  
//     html2canvas(input).then((canvas) => {
//       const imgData = canvas.toDataURL(logo);
//       const pdf = new jsPDF();
//       const imgWidth = 200;
//       const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
//       // Add logo
//       pdf.addImage(logo, "PNG", 120, 0, 80, 20); // Adjust position and size as needed
//       pdf.addImage(logo1, "PNG", 0, 0, 80, 20)
      
  
//       // Add watermark
//       pdf.setFontSize(20);
//       pdf.setTextColor(200);
//       pdf.text(logo, 10, 100); // Adjust position as needed
  
//       // Add table image
//       pdf.addImage(imgData, "PNG", 5, 80, imgWidth, imgHeight); // Adjust position and size as needed
  
//       // Open PDF in a new tab or window
//       const blobURL = URL.createObjectURL(pdf.output("blob"));
//       window.open(blobURL);
//     });
//   };

  

//   const camelToTitleCase = (str) => {
//     return str.replace(/([A-Z])/g, ' $1').replace(/^./, (s) => s.toUpperCase());
//   };
  
  

//   return (
//     <Box display="flex" height="100%">
//       {/* Chat History */}
//       <Box
//         flex="1"
//         p={"1rem"}
//         m={"2rem"}
//         borderRadius={5}
//         sx={{ boxShadow: 5 }}
//         style={{ display: isNotMobile ? "block" : "none" }}
//         backgroundColor={theme.palette.background.alt}
//       >
//         <ChatHistory response={response}  />
//       </Box>

//       {/* Chat Box */}
//       <Box
//         width={isNotMobile ? "70%" : "90%"}
//         p={"2rem"}
//         m={"2rem auto"}
//         borderRadius={5}
//         sx={{ boxShadow: 5 }}
//         backgroundColor={theme.palette.background.alt}
//       >
//         <Collapse in={error}>
//           <Alert severity="error" sx={{ mb: 2 }}>
//             {error}
//           </Alert>
//         </Collapse>
//         <form onSubmit={handleSubmit}>
//           <Typography variant="h3">Ask your query</Typography>

//           <TextField
//           fullWidth
//           multiline
//           placeholder="Enter your query"
//           value={title}
//           onChange={(e) => setTitle(e.target.value)}
//           style={{ marginRight: "1rem" }}
//             onKeyDown={(e) => {
//               if (e.key === 'Enter') {
//                 e.preventDefault();
//                 handleSubmit(e);
//               }
//             }}
//           />

//           <Button type="submit" fullWidth variant="contained" size="large" sx={{ color: "white", mt: 2 }}>
//             Submit
//           </Button>
//         </form>
//         {loading ? (
//           <Card
//           elevation={0}
//           sx={{
//             mt: 4,
//             height: "500px",
            
//             alignItems:'center'
//           }}
//         >
//           <Box sx={{
//                 textAlign: "center",
//                 verticalAlign: "middle",
//                 lineHeight: "180px",
//               }}>
//             <Fade
//               in={loading}
//               style={{
//                 transitionDelay: loading ? '800ms' : '0ms',
//               }}
//               unmountOnExit
//             >
//               <CircularProgress />
//             </Fade>
//           </Box>
          
//         </Card>
            
//           ) : (
//             response ? (
//               <div>
//                 {Array.isArray(response) ? (
//                   <div>
//                     <div style={{ right: 0 }}>
//                       <Button onClick={exportAsPDF}>Export as PDF</Button>
//                     </div>
//                     <Card
//                       sx={{
//                         mt: 4,
//                         border: 1,
//                         boxShadow: 0,
//                         height: "500px",
//                         borderRadius: 5,
//                         borderColor: "natural.medium",
//                         bgcolor: "background.default",
//                         overflow: "auto"
//                       }}
//                     >
//                       <Table ref={tableRef}>
//                         <TableHead>
//                           <TableRow>
//                             {Object.keys(response[0]).map((key, index) => (
//                               <TableCell key={index}>{camelToTitleCase(key)}</TableCell>
//                             ))}
//                           </TableRow>
//                         </TableHead>
//                         <TableBody>
//                           {response.map((item, index) => (
//                             <TableRow key={index}>
//                               {Object.values(item).map((value, idx) => (
//                                 <TableCell key={value}>{value}</TableCell>
//                               ))}
//                             </TableRow>
//                           ))}
//                         </TableBody>
//                       </Table>
//                     </Card>
//                   </div>
//                 ) : (
//                   <Card
//                     sx={{
//                       mt: 4,
//                       border: 1,
//                       boxShadow: 0,
//                       height: "200px",
//                       borderRadius: 5,
//                       borderColor: "natural.medium",
//                       bgcolor: "background.default",
//                     }}
//                   >
//                     <Typography
//                       variant="h6"
//                       color="natural.main"
//                       sx={{
//                         textAlign: "center",
//                         verticalAlign: "middle",
//                         lineHeight: "170px",
//                       }}
//                     >
//                       {(typeof response === "number" || typeof response === "string") && response !=" " && !loading
//                         ? response
//                         : "Response..."}
//                     </Typography>
//                   </Card>
//                 )}
//               </div>
//             ) : null
//           )}
//       </Box>
//     </Box>
//   );
// };

// export default Homepage;
// import React, { useEffect, useState, useContext, useRef } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import ChatHistory from "../components/ChatHistory";
// import axios from "axios";
// import logo from '../../src/logo.png';
// import logo1 from '../../src/ET.png';
// import {
//   Box,
//   Typography,
//   useTheme,
//   useMediaQuery,
//   TextField,
//   Button,
//   Alert,
//   Collapse,
//   Table,
//   TableHead,
//   TableRow,
//   TableCell,
//   TableBody,
//   CircularProgress,
//   Radio,
//   RadioGroup,
//   FormControlLabel,
//   FormControl,
//   FormLabel,
// } from "@mui/material";
// import { AuthContext } from "../private/AuthContext";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
// import CryptoJS from "crypto-js";

// // Configure the Elasticsearch client
// const esClient = axios.create({
//   baseURL: 'http://localhost:3001', // Your Elasticsearch server URL
// });

// const decryptData = (encryptedData) => {
//   try {
//     const bytes = CryptoJS.AES.decrypt(encryptedData, "secretKey");
//     const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
//     return JSON.parse(decryptedData);
//   } catch (error) {
//     console.error("Error decrypting data:", error);
//     return null;
//   }
// };

// const Homepage = () => {
//   const { chat } = useContext(AuthContext);
//   const [user, setUser] = useState({});
//   const theme = useTheme();
//   const navigate = useNavigate();
//   const isNotMobile = useMediaQuery("(min-width: 1000px)");
//   const [text, setText] = useState("");
//   const [response, setResponse] = useState([]);
//   const [error, setError] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [summary, setSummary] = useState(""); // State for text summary
//   const [viewMode, setViewMode] = useState("table"); // State to toggle between table and text view
//   const tableRef = useRef(null);

//   useEffect(() => {
//     const encryptedUserData = localStorage.getItem("userData");
//     if (encryptedUserData) {
//       const usr = decryptData(encryptedUserData);
//       setUser(usr);
//     }
//   }, []);

//   useEffect(() => {
//     if (chat) {
//       if (chat.query) {
//         setText(chat.query);
//         setResponse(JSON.parse(chat.llm_data));
//         setSummary(chat.summary || "");
//       }
//     }
//   }, [chat]);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setResponse([]);
//     setSummary(""); // Clear summary before fetching a new one
//     setLoading(true);
//     try {
//       // Send search query to Elasticsearch
//       const response = await esClient.get('/', {
//         params: {
//           q: text, // Simple text search query
//           index: 'mediasathi' // Replace with your index name
//         }
//       });
//       console.log(response.data);

//       // Process the response
//       const hits = response.data.hits.hits;
//       const processedHits = hits.map(hit => ({
//         id: hit._id,
//         content: hit._source.content // Adjust according to your data structure
//       }));

//       setResponse(processedHits);
//       setLoading(false);
//     } catch (err) {
//       setLoading(false);
//       console.error("Error fetching data:", err);
//       if (err?.response?.data?.error) {
//         setError(err.response.data.error);
//       } else if (err?.message) {
//         setError(err.message);
//       }
//       setTimeout(() => {
//         setError("");
//       }, 5000);
//     }
//   };

//   const exportAsPDF = () => {
//     const input = document.getElementById('root'); // Assuming your root element has an ID of 'root'
//     html2canvas(input)
//       .then((canvas) => {
//         const imgData = canvas.toDataURL('image/png');
//         const pdf = new jsPDF();
//         pdf.addImage(imgData, 'JPEG', 0, 0);
//         pdf.save("download.pdf"); // Generates a download link for the PDF
//       });
//   };

//   const camelToTitleCase = (str) => {
//     return str.replace(/([a-z])([A-Z])/g, '$1 $2')
//              .replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
//   };

//   return (
//     <Box display="flex" height="100%">
//       {/* Chat History */}
//       <Box
//         width={isNotMobile ? "300px" : "100%"}
//         p={"1rem"}
//         m={"2rem"}
//         borderRadius={5}
//         sx={{ boxShadow: 5 }}
//       >
//         {response.map((item, index) => (
//           <ChatHistory key={index} message={item.content} />
//         ))}
//       </Box>
//       {/* Main Content */}
//       <Box
//         flex="1"
//         p={"1rem"}
//         m={"2rem"}
//         borderRadius={5}
//         sx={{ boxShadow: 5 }}
//         style={{ display: "flex", flexDirection: "column", height: "100%" }}
//       >
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//             marginBottom: "1rem",
//           }}
//         >
//           <Typography variant="h4">Enlighten Talents</Typography>
//           <Button onClick={exportAsPDF} variant="contained" color="primary">
//             Export as PDF
//           </Button>
//         </Box>
//         <form onSubmit={handleSubmit} style={{ display: "flex", marginBottom: "1rem" }}>
//           <TextField
//             fullWidth
//             multiline
//             placeholder="Enter your query"
//             value={text}
//             onChange={(e) => setText(e.target.value)}
//             style={{ marginRight: "1rem" }}
//           />
//           <Button type="submit" variant="contained" color="primary">
//             Submit
//           </Button>
//         </form>
//         {error && (
//           <Collapse in={!!error}>
//             <Alert severity="error">{error}</Alert>
//           </Collapse>
//         )}
//         {loading && (
//           <Box display="flex" justifyContent="center" my={2}>
//             <CircularProgress />
//           </Box>
//         )}
//         <Box>
//           <FormControl component="fieldset">
//             <FormLabel component="legend">View Mode</FormLabel>
//             <RadioGroup
//               row
//               value={viewMode}
//               onChange={(e) => setViewMode(e.target.value)}
//             >
//               <FormControlLabel value="table" control={<Radio />} label="Table View" />
//               <FormControlLabel value="text" control={<Radio />} label="Text View" />
//             </RadioGroup>
//           </FormControl>
//         </Box>
//         {viewMode === "table" ? (
//           <Box flex="1" overflow="auto" ref={tableRef}>
//             <Table>
//               <TableHead>
//                 <TableRow>
//                   {response.length > 0 && Object.keys(response[0]).map((key) => (
//                     <TableCell key={key}>{camelToTitleCase(key)}</TableCell>
//                   ))}
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {response.map((row, index) => (
//                   <TableRow key={index}>
//                     {Object.values(row).map((value, i) => (
//                       <TableCell key={i}>{value}</TableCell>
//                     ))}
//                   </TableRow>
//                 ))}
//               </TableBody>
//             </Table>
//           </Box>
//         ) : (
//           <Box flex="1" overflow="auto" p={2} border="1px solid #ccc">
//             <Typography variant="h6">Summary</Typography>
//             <Typography>{summary}</Typography>
//           </Box>
//         )}
//       </Box>
//     </Box>
//   );
// };
// export default Homepage;

import React, { useEffect, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  Collapse,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import { AuthContext } from "../private/AuthContext";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import CryptoJS from "crypto-js";
import { useTheme, useMediaQuery } from "@mui/material";

// Assuming esClient is configured elsewhere and imported correctly
const esClient = axios.create({
  baseURL: "https://180c541582f1482daa80ab32fe30213d.us-east-1.aws.found.io:443",
  headers: {
    Authorization: "Basic " + btoa("elastic:Tcqq55csRYLVbgegiCFWmN46"),
  },
});

const Homepage = () => {
  const { chat } = useContext(AuthContext);
  const [user, setUser] = useState({});
  const theme = useTheme();
  const navigate = useNavigate();
  const isNotMobile = useMediaQuery("(min-width: 1000px)");
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const [response, setResponse] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState(""); // State for text summary
  const [viewMode, setViewMode] = useState("table"); // State to toggle between table and text view
  const tableRef = useRef(null);

  useEffect(() => {
    const encryptedUserData = localStorage.getItem("userData");
    if (encryptedUserData) {
      const usr = decryptData(encryptedUserData);
      setUser(usr);
    }
  }, []);

  useEffect(() => {
    if (chat) {
      if (chat.query) {
        setTitle(chat.query);
        setResponse(JSON.parse(chat.llm_data));
        setSummary(chat.summary || "");
      }
    }
  }, [chat]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setResponse([]);
    setSummary(""); 
    setLoading(true);
    try {
      const targetUrl = "https://www.mediasathi.ai:8081/api/scraper/searchWithBedrock";
  
      const response = await axios({
        method: "POST",
        url: targetUrl,
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + btoa("elastic:Tcqq55csRYLVbgegiCFWmN46"),
        },
        params: {
          question: title,
        },
      });
  
      console.log("Backend Response:", response.data);
  
      // Check if response.data and response.data.hits exist
      if (response.data && response.data.hits) {
        const hits = response.data.hits.hits;
  
        // Process each hit and filter out any failed summaries
        const processedHits = hits
          .filter((hit) => !hit._source.summary.includes("Failed to generate summary"))
          .map((hit) => ({
            id: hit._id,
            url: hit._source.url,
            summary: hit._source.summary.replace(/.*summary of the key points.*\n\n/, "").trim(),
          }));
  
        setResponse(processedHits);
      } else {
        setResponse(response.data); // Store the raw response data
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error("Error fetching data:", err);
      if (err?.response?.data?.error) {
        setError(err.response.data.error);
      } else if (err?.message) {
        setError(err.message);
      } else {
        setError("An unexpected error occurred.");
      }
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  };
  
  const decryptData = (encryptedData) => {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedData, "secretKey");
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptedData);
    } catch (error) {
      console.error("Error decrypting data:", error);
      return null;
    }
  };

  const exportAsPDF = () => {
    const input = document.getElementById("root");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 0, 0);
      pdf.save("download.pdf");
    });
  };

  const camelToTitleCase = (str) => {
    return str
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
  };

  return (
    <Box display="flex" height="100%" flexDirection="column" p={"2rem"}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="1rem"
      >
        <Typography variant="h4">Ask Your Question</Typography>
        <Button onClick={exportAsPDF} variant="contained" color="primary">
          Export as PDF
        </Button>
      </Box>

      <form onSubmit={handleSubmit} style={{ display: "flex", marginBottom: "1rem" }}>
        <TextField
          fullWidth
          multiline
          placeholder="Enter your query"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          style={{ marginRight: "1rem" }}
        />
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </form>

      {error && (
        <Collapse in={!!error}>
          <Alert severity="error">{error}</Alert>
        </Collapse>
      )}

      {loading && (
        <Box display="flex" justifyContent="center" my={2}>
          <CircularProgress />
        </Box>
      )}

      <Box>
        <FormControl component="fieldset">
          <FormLabel component="legend">View Mode</FormLabel>
          <RadioGroup
            row
            value={viewMode}
            onChange={(e) => setViewMode(e.target.value)}
          >
            <FormControlLabel value="table" control={<Radio />} label="Table View" />
            <FormControlLabel value="text" control={<Radio />} label="Text View" />
          </RadioGroup>
        </FormControl>
      </Box>

      {viewMode === "table" ? (
        <Box flex="1" overflow="auto" ref={tableRef}>
          <Table>
            <TableHead>
              <TableRow>
                {response.length > 0 &&
                  Object.keys(response[0]).map((key) => (
                    <TableCell key={key}>{camelToTitleCase(key)}</TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {response.map((row, index) => (
                <TableRow key={index}>
                  {Object.values(row).map((value, i) => (
                    <TableCell key={i}>
                      {i === 1 ? (
                        <a href={value} target="_blank" rel="noopener noreferrer">
                          {value}
                        </a>
                      ) : (
                        value
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      ) : (
        <Box flex="1" overflow="auto" p={2} border="1px solid #ccc">
          <Typography variant="h6">Summary</Typography>
          {response.map((row, index) => (
            <Box key={index} mb={2}>
              <Typography variant="subtitle1">
                <a href={row.url} target="_blank" rel="noopener noreferrer">
                  {row.url}
                </a>
              </Typography>
              <Typography>{row.summary}</Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default Homepage;