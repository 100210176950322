// AuthContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import Cookies from 'js-cookie';

const AuthContext = createContext();


const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(Cookies.get('token') || null);
  const [user, setUser] = useState(null);
  const [chat, setChat] = useState({});

  useEffect(() => {
    const tokenFromCookie = Cookies.get('token');
    if (tokenFromCookie) {
      setToken(tokenFromCookie);
    }
  }, []);

   
   
    //chat history provider
    const addMessage = (message) => {
      setChat(message);
    };

  const loginUser = (userData) => {
    //console.log("userData", userData);
    setUser(userData);
  };
  const logoutUser = () => {
    //console.log("loggedout");
    setUser(null);
  };

  const login = (token) => {
    Cookies.set('token', token, { expires: 7 }); // Set token to expire in 7 days
    setToken(token);
  };

  const logout = () => {
    Cookies.remove('token');
    setToken(null);
  };

  return (
    <AuthContext.Provider value={{ token, login, logout,logoutUser,loginUser,user,addMessage,chat }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth, AuthContext };

