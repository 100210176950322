import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Drawer, useMediaQuery, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import logo from '../../src/logo.png'
import axios from "axios";
import toast from "react-hot-toast";
import ChatHistory from "./ChatHistory";
import { AuthContext } from "../private/AuthContext";
import CryptoJS from "crypto-js";
const decryptData = (encryptedData) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, "secretKey");
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData);
  } catch (error) {
    console.error("Error decrypting data:", error);
    return null;
  }
};
const Navbar = () => {
  const { logoutUser } = useContext(AuthContext);
  const [isAdmin, setIsAdmin] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const loggedIn = localStorage.getItem("userData");
  useEffect(()=>{
    const encryptedUserData = localStorage.getItem("userData");
    if (encryptedUserData) {
      const user = decryptData(encryptedUserData);
      if (user.is_admin) {
        setIsAdmin(true);
        
      }
    }
  },[])

  //handle logout
  const handleLogout = async () => {
    try {
      await axios.post("/api/v1/auth/logout");
      localStorage.removeItem("authToken");
      toast.success("logout successfully ");
      navigate("/login");
      localStorage.removeItem("userData")
      logoutUser("")
    } catch (error) {
      console.log(error);
    }
  };
  const [open, setOpen] = useState(false);
  const isNotMobile = useMediaQuery("(min-width: 1000px)");

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  return (
    <Box
      width={"100%"}
      backgroundColor={theme.palette.background.alt}
      p="1rem 6%"
      textAlign={"center"}
      sx={{ boxShadow: 3, mb: 2 }}
    >
      {/* <Typography variant="h1" color="primary" fontWeight="bold">
        Media sathi
      </Typography> */}
      <Link to="/">
          <img src={logo} height="50%" width="50%" alt="media_sathi_logo" />
      </Link>
      {loggedIn ? (
        <>
          <Box flex={1}>
              {
                isNotMobile? '' : <Button onClick={toggleDrawer(true)} style={{textTransform:"none", color:"#60bf70"}} 
              
                >Previous chats</Button>
              }
              
              <NavLink to="/login" onClick={handleLogout} p={1}>
                Logout
              </NavLink>
              {
                isAdmin ? (
                  <>
                      <NavLink to="/admin"  p={1}>
                        Configurations
                      </NavLink>
                  </>
                ):(
                  <>
                  </>
                )
              }
          </Box>

          <Drawer open={open} onClose={toggleDrawer(false)}>
            <ChatHistory />
          </Drawer>
        </>
      ) : (
        <>
          
        </>
      )}
    </Box>
  );
};

export default Navbar;
