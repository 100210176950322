// ProtectedRoute.js
import React,{useEffect} from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

import { useAuth } from './AuthContext';

const PrivateRoute = (props) => {
  const navigate = useNavigate();
  const {Component} = props;
  useEffect(() => {
    const tokenFromCookie = localStorage.getItem("userData")
    if(!tokenFromCookie){
        navigate('/login')
    }
  }, [navigate]);

  return (
    <>
   
        <Component />
    </>
  );
};

export default PrivateRoute;
