// import React, { useContext, useEffect, useState } from 'react';
// import { Box, Typography, List, ListItem, ListItemText, ButtonBase, IconButton } from '@mui/material';
// import CryptoJS from "crypto-js";
// import { AuthContext } from '../private/AuthContext';
// import axios from 'axios';
// import DeleteIcon from '@mui/icons-material/Delete';






// const ChatHistory = ({response}) => {
//   const { loginUser,addMessage } = useContext(AuthContext);
//   const [user, setUser] = useState("")
//   const [chatData, setChatData] = useState([]);
//   const handleItemClick = (message) => {
    
//     addMessage(message);
//     // Add your onClick logic here

   
//   };
//   const handleDeleteChat = async (chatId) => {
//     try {
//       // Make a DELETE request to delete the chat
//       await axios.delete(`/api/v1/openai/chat/${chatId}`);
//       // Update the chat data state by filtering out the deleted chat
//       fetchHistory();
      
      
//     } catch (error) {
//       console.error('Error deleting chat:', error);
//       // Handle error
//     }
//   };
//   const fetchHistory = async () => {
//     try {
//       const response = await axios.get("/api/v1/openai/chat");
//       const result = response.data;
//       setChatData(result.chatHistory.reverse().slice(0,10));
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };
  
//   useEffect(() => {

//     fetchHistory();
    
//   }, [response]);
  
  

//   return (
//     <Box p={2} height="100%"  overflow="auto">
//     <Typography variant="h6" gutterBottom>
//       Chat History
//     </Typography>
//     <List sx={{ width: '300px', minWidth: 360, bgcolor: 'background.paper', paddingX:0 }}
//       >
//       {chatData.map((chat, index) => (
//         <ListItem
//         key={index}
//         disableGutters
//         sx={{
//           '&:hover': {
//             backgroundColor: '#ddd', // Change to your desired hover color
//             boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
//           },
//           cursor:'pointer',
//           paddingX:4,
//           boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
//         }}
//       >
//         <ListItemText onClick={()=>handleItemClick(chat)} primary={` ${chat.query}`} sx={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',
//             }}/>
//             <IconButton onClick={() => handleDeleteChat(chat.id)} aria-label="delete" color="error">
//             <DeleteIcon />
//             </IconButton>
//       </ListItem>
        
//       ))}
//     </List>
//   </Box>
  
//   );
// };

// export default ChatHistory;
