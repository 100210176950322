// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "roboto", sans-serif;
}

a {
  color: #60bf70 !important;
  text-decoration: none;
  padding: 20px;
  margin-top: 20px;
}

a:hover {
  color: black !important;
}

a.active {
  color: #57d96d !important;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["* {\r\n  margin: 0;\r\n  padding: 0;\r\n  box-sizing: border-box;\r\n}\r\n\r\nbody {\r\n  font-family: \"roboto\", sans-serif;\r\n}\r\n\r\na {\r\n  color: #60bf70 !important;\r\n  text-decoration: none;\r\n  padding: 20px;\r\n  margin-top: 20px;\r\n}\r\n\r\na:hover {\r\n  color: black !important;\r\n}\r\n\r\na.active {\r\n  color: #57d96d !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
