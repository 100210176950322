import "./App.css";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";

import { createTheme } from "@mui/material/styles";
import { Toaster } from "react-hot-toast";
import { themeSettings } from "./theme";
import Navbar from "./components/Navbar";
import Homepage from "./pages/Homepage";
import Register from "./pages/Register";
import Login from "./pages/Login";
import PrivateRoute from "./private/PrivateRoute";
import { AuthProvider, useAuth } from './private/AuthContext';
import Adminpage from "./pages/Adminpage";
import CryptoJS from "crypto-js";
const decryptData = (encryptedData) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, "secretKey");
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData);
  } catch (error) {
    console.error("Error decrypting data:", error);
    return null;
  }
};

function App() {
  const theme = useMemo(() => createTheme(themeSettings()), []);
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(()=>{
    const encryptedUserData = localStorage.getItem("userData");
    if (encryptedUserData) {
      const user = decryptData(encryptedUserData);
      if (user.is_admin) {
        setIsAdmin(true);
        
      }
    }
  },[isAdmin])
 

  return (
    // <ThemeProvider theme={theme}>
    //   <CssBaseline />
     
        
      <>
      <AuthProvider>
      <Navbar />
        <Toaster />
      
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          {
            isAdmin? <Route path="/admin" element={<Adminpage />} /> : <Route path="/" element={<PrivateRoute Component={Homepage } />} />
          }
         
          {/* Example of a protected route */}
          <Route path="/" element={<PrivateRoute Component={Homepage } />} />
        </Routes>
      
    </AuthProvider>
      
      </>
    // </ThemeProvider>
  );
}

export default App;
