import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  TextField,
  Button,
  Alert,
  Collapse,
} from "@mui/material";
import { AuthContext } from '../private/AuthContext';
import CryptoJS from "crypto-js";

const Login = () => {
  const { loginUser, user } = useContext(AuthContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const isNotMobile = useMediaQuery("(min-width: 1000px)");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const decryptData = (encryptedData) => {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedData, "secretKey");
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptedData);
    } catch (error) {
      console.error("Error decrypting data:", error);
      return null;
    }
  };

  useEffect(()=>{

    const encryptedUserData = localStorage.getItem("userData");
    if (encryptedUserData) {
      const user = decryptData(encryptedUserData);
      if (user.id) {
        navigate('/');
        
      }
    }
    

  },[ navigate])

  // Function to encrypt data
  const encryptData = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), "secretKey").toString();
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/api/v1/auth/login", { email, password });
      const user = response.data.user;
      if (user) {
        // Encrypt and store user data in localStorage
        const encryptedUserData = encryptData(user);
        localStorage.setItem("userData", encryptedUserData);
        // Update user context
        loginUser(user);
        // Show success message
        toast.success("Login Successful");
        // Redirect to home page
        navigate("/");
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.data && err.response.data.error) {
        setError(err.response.data.error);
      } else {
        setError("An error occurred during login");
      }
    }
  };

  // When the component mounts, check if there's encrypted user data in localStorage and decrypt it


  return (
    <Box
      width={isNotMobile ? "40%" : "80%"}
      p={"2rem"}
      m={"2rem auto"}
      borderRadius={5}
      sx={{ boxShadow: 5 }}
      bgcolor={theme.palette.background.alt}
    >
      <Collapse in={error}>
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      </Collapse>
      <form onSubmit={handleSubmit}>
        <Typography variant="h3">Sign In</Typography>

        <TextField
          label="Email"
          type="email"
          required
          margin="normal"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Password"
          type="password"
          required
          margin="normal"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          size="large"
          sx={{ color: "white", mt: 2 }}
        >
          Sign In
        </Button>
        <Typography mt={2}>
          Don't have an account? <Link to="/register">Register</Link>
        </Typography>
      </form>
    </Box>
  );
};

export default Login;
