import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { TextField, Button, Grid, Paper } from '@mui/material';
import axios from 'axios';
import { Collapse, Alert } from '@mui/material';
import toast from 'react-hot-toast';


const useStyles = makeStyles({
  paper: {
    padding: '20px',
    maxWidth: '400px',
    margin: 'auto',
    marginTop: '50px',
    borderRadius: '10px',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#f9f9f9',
  },
  input: {
    marginBottom: '20px',
  },
  submitBtn: {
    marginTop: '20px',
  },
});


function Adminpage() {
  const [error, setError] = useState('');
  const classes = useStyles();
  const [clientName, setClientName] = useState('');
  const [databaseName, setDatabaseName] = useState('');
  const [apiFile, setApiFile] = useState(null); // Use null instead of undefined
  const [schemaFile, setSchemaFile] = useState(null); // Use null instead of undefined
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('clientName', clientName);
    formDataToSend.append('databaseName', databaseName);
    formDataToSend.append('username', username);
    formDataToSend.append('password', password);
    formDataToSend.append('apiFile', apiFile);
    formDataToSend.append('schemaFile', schemaFile);

    console.log("formDataToSend", formDataToSend);
    console.log("formData", clientName, databaseName, username, password, apiFile, schemaFile);

    try {
      const response = await axios.post('/api/v1/openai/configuration', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
      toast.success('Configuration saved Successfully');
      // Reset form fields after successful submission
      setClientName('');
      setDatabaseName('');
      setUsername('');
      setPassword('');

    } catch (error) {
      console.error('Error saving configuration:', error);
      setError(error.message || 'Failed to save configuration');
      setTimeout(() => {
        setError('');
      }, 5000);
    }
  };

  return (
    <Paper className={classes.paper}>
      <Collapse in={error}>
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      </Collapse>
      <form onSubmit={handleSubmit}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
          <TextField
            className={classes.input}
            fullWidth
            label="Client Name"
            name="clientName"
            value={clientName}
            onChange={(e) => setClientName(e.target.value)}
            variant="outlined"
            required
            data-testid='clientName'
          />
          </Grid>
          <Grid item>
          <TextField
            className={classes.input}
            fullWidth
            label="Database Name"
            name="databaseName"
            value={databaseName}
            onChange={(e) => setDatabaseName(e.target.value)}
            variant="outlined"
            data-testid='databaseName'
          />
          </Grid>
          <Grid item>
          <input
            className={classes.input}
            type="file"
            accept=".pdf"
            name="apiFile"
            onChange={(e) => setApiFile(e.target.files[0])}
            data-testid="apiFile" // Add data-testid directly to the input element
          />
          </Grid>
          <Grid item>
              
          <input
            className={classes.input}
            type="file"
            accept=".zip, .sql"
            name="schemaFile"
            onChange={(e) => setSchemaFile(e.target.files[0])}
            data-testid="schemaFile" // Add data-testid directly to the input element
          />
            
          </Grid>
          <Grid item>
          <TextField
            className={classes.input}
            fullWidth
            label="Username"
            name="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            variant="outlined"
            data-testid='username-input'
          />
          </Grid>
          <Grid item>
          <TextField
            className={classes.input}
            fullWidth
            label="Password"
            type="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            variant="outlined"
            data-testid= 'password-input'
          />
          </Grid>
          <Grid item>
            <Button
              className={classes.submitBtn}
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}

export default Adminpage;
